import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom';
import {Logo,Facebook,Twitter,Linkedin,Youtube} from "../Imports/ImportImages";

function Footer() {
  return (
    <footer>
        <Container>
            <div className='footer-wrapper'>
                <Row>
                    <Col lg={5}>
                       <div className='footer-box'>
                            <div className='footer-logo'>
                                <Link to="/">
                                    <img src={Logo}/>
                                </Link>
                            </div>
                            <div className='text'>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. varius lacus vel elit accumsan, sollicitudin.</p>
                            </div>
                            <div className='social-icons'>
                                <Link to="/">
                                    <img src={Facebook}/>
                                </Link>
                                <Link to="/">
                                    <img src={Twitter}/>
                                </Link>
                                <Link to="/">
                                    <img src={Youtube}/>
                                </Link>
                                <Link to="/">
                                    <img src={Linkedin}/>
                                </Link>
                            </div>
                       </div>
                    </Col>
                    <Col lg={7}>
                        <Row>
                            <Col xs={4}>
                               <div className='footer-box'>
                                  <h4>Company</h4>
                                  <ul>
                                    <li><Link to="">Wishlist</Link></li>
                                    <li><Link to="">Cart</Link></li>
                                    <li><Link to="">Checkout</Link></li>
                                    <li><Link to="">Maintenance Mode</Link></li>
                                  </ul>
                               </div>
                            </Col>
                            <Col xs={4}>
                               <div className='footer-box'>
                                  <h4>Links</h4>
                                  <ul>
                                    <li><Link to="">About Us</Link></li>
                                    <li><Link to="">Blog</Link></li>
                                    <li><Link to="">Services</Link></li>
                                    <li><Link to="">Contact</Link></li>
                                  </ul>
                               </div>
                            </Col>
                            <Col xs={4}>
                               <div className='footer-box'>
                                  <h4>Help</h4>
                                  <ul>
                                    <li><Link to="">Email Us</Link></li>
                                    <li><Link to="">Help & FAQ</Link></li>
                                    <li><Link to="">Shipping Policy</Link></li>
                                    <li><Link to="">Gift Cards</Link></li>
                                  </ul>
                               </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <div className='footer-bottom'>
                    <p>Copyright 2022 Fable. All Right Reserved.</p>
                    <p>Terms of Service  |  Privacy Policy</p>
                </div>
            </div>
        </Container>
    </footer>
  )
}

export default Footer