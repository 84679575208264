import React,{useState} from 'react'
import {Logo,Search,User,ToggleButton, BlackLogo,SearchBlack, UserBlack,InsedeHToggle} from "../Imports/ImportImages";
import {Link} from 'react-router-dom'
import {Container} from 'react-bootstrap';

const Header = (props) => {

  // Mobile Menu Active

  const [navActive, setNavActive] = useState(false);

    return (
      <React.Fragment>
          {props.HeaderInside ? (
            <header className='header-inside'>
              <Container>
              <div className="header-wrapper">
                <div className='toggle-menu'>
                  <div className='toggle' onClick={() => setNavActive(!navActive)}>
                    <img src={InsedeHToggle}/>
                  </div>
                </div>
                <div className='logo'>
                  <Link to="/Index">
                    <img src={BlackLogo} alt="" />
                  </Link>
                </div>
                <div className={`navigation-items ${navActive? 'active' : ''}`}>
                  <ul className='nav'>
                    <li> 
                      <Link to="/Index">
                        Home
                      </Link>
                    </li>
                    <li>
                      <Link to="/Clublist">Club List
                      </Link>
                    </li>
                    <li>
                      <Link to="/Profile">About Us</Link>
                    </li>
                    <li>
                      <Link to="/SignUp">Contact
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className='search-user'>
                  <Link to="/" className='search'>
                    <img src={SearchBlack}/>
                  </Link>
                  <Link to="/" className='user'>
                    <img src={UserBlack}/>
                  </Link>
                </div>
              </div>
              </Container>
            </header>
            ) : (
              <header>
              <Container>
              <div className="header-wrapper">
                <div className='toggle-menu'>
                  <div className='toggle' onClick={() => setNavActive(!navActive)}>
                    <img src={ToggleButton}/>
                  </div>
                </div>
                <div className='logo'>
                  <Link to="/Index">
                    <img src={Logo} alt="" />
                  </Link>
                </div>
                <div className={`navigation-items ${navActive? 'active' : ''}`}>
                  <ul className='nav'>
                      <li> 
                        <Link to="/Index">
                          Home
                        </Link>
                      </li>
                    <li>
                      <Link to="/Clublist">Club List
                      </Link>
                    </li>
                    <li>
                      <Link to="/Profile">About Us</Link>
                    </li>
                    <li>
                      <Link to="/SignUp">Contact
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className='search-user'>
                  <Link to="/" className='search'>
                    <img src={Search}/>
                  </Link>
                  <Link to="/" className='user'>
                    <img src={User}/>
                  </Link>
                </div>
              </div>
              </Container>
            </header>
            )
          }
    </React.Fragment>
    )
}

export default Header